// Footer.scss
.footer {
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 40px 0; // Maintained original top and bottom padding
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0; // This prevents the footer from shrinking
}

.footer-brand {
  display: flex;
  width: 100%;
  max-width: 1360px;
  padding: 0 40px; // Adjusted to match the header logo's padding
  margin: 0 auto; // Centered like the navigation bar
  align-items: center;
  justify-content: flex-start;
}

.footer-logo img {
  width: 60px; // Logo size is fixed and does not change
  height: auto;
  display: block;
  margin-left: 40px; // Added the same margin as the header logo
}

.footer-details {
  display: flex;
  justify-content: space-evenly; // Changed from space-between to space-evenly
  width: 100%;
  max-width: 1360px;
  padding: 0 40px; // Adjusted to match the header padding
  margin: 0 auto; // Centered like the navigation bar
  align-items: flex-start;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 40px; // This is necessary for aligning with the logo
  margin-top: 40px;
  flex: 1; // This ensures that the space is divided equally
}

.footer-section h3 {
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  margin: 0 0 10px;
  text-transform: uppercase;
  color: #fff;
}

.footer-section .text-white, 
.footer-section div {
  font-size: 20px;
  font-family: 'Amiri', sans-serif;
  color: #fff;
  margin: 0;
  line-height: 1.5;
  text-decoration: none;
}

.social-icons {
  display: flex;
  justify-content: flex-start;
  a {
    color: white;
    margin-right: 25px;
    &:hover {
      color: #bbb;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .fa-icon {
    font-size: 24px;
    vertical-align: middle;
  }
}

.footer-subtext {
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-family: 'Amiri', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 20px;
  align-self: center;
}

// Media Queries
@media (max-width: 1360px) {
  .footer-brand, .footer-details {
    padding: 0 10px; // Smooth transition padding
  }
}

@media (max-width: 1024px) {
  .footer-brand, .footer-details {
    padding: 0 20px; // Consistent padding
  }
}


@media (max-width: 768px) {
  .footer-brand {
    justify-content: center; // Centers the logo container
    padding-bottom: 20px; // Adds some space below the logo
  }
  .footer-details {
    flex-direction: column;
    align-items: center; // Centers the footer sections
  }
  .footer-section {
    align-items: center; // Centers the content of each footer section
    margin: 20px 0; // Adds vertical spacing and removes horizontal margin
  }
  .social-icons {
    justify-content: center; // Centers the social icons
  }
  .footer-logo img {
    margin-left: 0; // Removes the 40px margin from the logo
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px; // Simplified padding for a consistent look
  }
  .footer-brand {
    padding-bottom: 20px; // Maintains space below the logo
  }
  .footer-brand, .footer-details {
    padding: 0; // Removes padding to use the full width
  }
  .social-icons a {
    margin-right: 15px; // Adjusts the margin for social icons
  }
  .social-icons {
    justify-content: center; // Ensures social icons are centered
    margin-top: 20px; // Adds space above the social icons
  }
}
