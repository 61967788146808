@import './components/Experiences.scss';
// @import './components/Projects/FleetStatusDashboard.scss';

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

body { margin: 0; }

.App-header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Amiri', sans-serif;
  font-size: 20px;
  line-height: 27px;
  color: #333;
  -webkit-font-smoothing: antialiased;
}

.container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0 auto;
}

.navigation-bar {
  width: 100%;
  max-width: 1360px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0 auto;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Ensure it fills the full viewport height
  justify-content: space-between; // Space between header and footer
  font-size: calc(10px + 2vmin);
  color: #333;
  background-color: #fff;
  animation: fadeIn 1s ease-in-out;
}


.main {
  max-width: 1360px;
  margin: auto; // Center horizontally
  padding: 160px 40px; // Padding top and bottom, adjust left and right as needed
  flex-grow: 1;
}

.button {
  display: inline-block;
  height: 60px;
  padding: 10px 30px;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
  background-color: transparent;
  transition: background-color 200ms ease, color 200ms ease;
  font-family: Poppins, sans-serif;
  color: #222;
  font-size: 13px;
  line-height: 41px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  margin: 40px; // Original margin
  &:hover {
    background-color: #222;
    color: #fff;
  }
}

.logo {
  height: auto;
  max-height: 60px; // Original logo height
  width: auto; // Keep the logo aspect ratio
  position: relative;
  margin-left: 40px; // Adjusted to match the button's margin
}

.selected-work h2 {
  color: #333333;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 40px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

@media screen and (max-width: 1024px) {
  .navigation-bar, .main {
    padding: 10px 10px; // Consistent padding on all sides
  }
}

@media screen and (max-width: 768px) {
  .navigation-bar, .main {
    padding: 20px 10px; // Increased top and bottom padding for a smoother transition
  }

  .logo, .button {
    margin-top: 10px; // Retains space above, removes abrupt jump
    margin-bottom: 10px; // Retains space below
  }
}

@media screen and (max-width: 480px) {
  .navigation-bar, .main {
    padding: 20px; // Apply a consistent 20px padding
    margin: 0; // Reset any specific margins if necessary
  }

  .logo {
    margin-left: 20px; // Adjust the left margin for the smallest screens
    margin-top: 5px; // Slight top margin
    margin-bottom: 5px; // Slight bottom margin
  }

  .button {
    margin-top: 5px; // Slight top margin
    margin-bottom: 5px; // Slight bottom margin
    padding-left: 10px; // Adjust if necessary to align with the logo's new margin
    padding-right: 10px; // Adjust if necessary to align with the logo's new margin
    transform: translateX(-20px);
  }

}