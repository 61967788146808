$base-font-size: 16px;

@use "sass:math";

@function px-to-rem($size) {
  @return math.div($size, $base-font-size) * 1rem;
}

@function fluid-type($min-vw, $max-vw, $min-size, $max-size) {
  @return clamp(px-to-rem($min-size), calc(#{px-to-rem($min-size)} + #{math.div($max-size - $min-size, $max-vw - $min-vw)} * (100vw - #{$min-vw})), px-to-rem($max-size));
}

.projectPage {
  padding-top: px-to-rem(144.2px);
  padding-bottom: px-to-rem(80px);
  display: flex;
  justify-content: center;
  width: 100%;
}

.contentContainer {
  max-width: 1280px;
  width: 100%;
  padding-left: px-to-rem(15px);
  padding-right: px-to-rem(15px);
  text-align: left;
}

h1 {
  font-size: fluid-type(320px, 1920px, 24px, 32px);
  margin: 0 0 px-to-rem(20px);
  color: #333333;
  font-family: 'Amiri', sans-serif;
  font-weight: normal;
  text-align: left;
}

.infoSection {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid rgba(34, 34, 34, 0.1);
  padding: px-to-rem(20px) 0;
}

.infoColumn,
.linkColumn {
  flex: 1 1 px-to-rem(300px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 px-to-rem(20px);
  .title {
    font-size: px-to-rem(13px);
    font-family: 'Poppins', sans-serif;
    color: #22222280;
  }
  .detail,
  a {
    font-size: px-to-rem(18px);
    font-family: 'Amiri', sans-serif;
    color: #333333;
    margin-top: px-to-rem(5px);
    text-decoration: none;
  }
}

.image {
  width: 100%;
  height: auto;
  margin-top: px-to-rem(40px);
  margin-bottom: px-to-rem(20px);
}

.dashboardImage {
  width: 100%;
  height: auto;
  margin-top: px-to-rem(40px);
  margin-bottom: px-to-rem(20px);
}

.projectSummaryText {
  font-size: px-to-rem(28px);
  font-family: 'Amiri', sans-serif;
  color: #333333;
  margin: px-to-rem(80px) auto px-to-rem(40px);
  padding: 0 px-to-rem(40px);
  max-width: 770px;
  text-align: center;
}

figure {
  text-align: center;
  margin-top: px-to-rem(40px);
  .image {
    display: block;
    max-width: 100%;
    height: auto;
    margin-top: px-to-rem(80px);
    margin-left: auto;
    margin-right: auto;
    border: 0.5px solid black;
  }
  figcaption {
    margin-top: px-to-rem(5px);
    font-size: px-to-rem(16px);
    font-style: italic;
    font-family: 'Amiri', sans-serif;
    text-align: center;
    margin-bottom: px-to-rem(80px);
  }
}

.paragraphSection {
  text-align: left;
  max-width: 600px;
  margin: px-to-rem(80px) auto px-to-rem(20px);
  padding: 0 px-to-rem(20px);
  
  h2 {
    font-size: fluid-type(320px, 1920px, 24px, 30px);
    font-family: 'Amiri', sans-serif;
    color: #333333;
    margin: 0px 0px px-to-rem(10px);
    line-height: px-to-rem(40px);
    margin-top: px-to-rem(80px);
    text-align: left;
  }

  p {
    font-size: fluid-type(320px, 1920px, 16px, 20px);
    font-family: 'Amiri', sans-serif;
    color: #333333;
    margin: px-to-rem(20px) 0;
    line-height: px-to-rem(30px);
    text-align: left;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(px-to-rem(20px));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
  animation-delay: 0.3s;
}

@media (max-width: 1024px) {
  .infoSection {
    flex-direction: column;
    .infoColumn,
    .linkColumn {
      padding-left: 0;
      padding-right: 0;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(34, 34, 34, 0.1);
      }
    }
  }
}

@media (max-width: 768px) {
  .projectSummaryText,
  .paragraphSection {
    padding: 0 px-to-rem(20px);
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: px-to-rem(20px);
  }

  .paragraphSection h2 {
    font-size: px-to-rem(22px);
  }

  .paragraphSection p {
    font-size: px-to-rem(16px);
  }
}
