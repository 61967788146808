@use "sass:math";

$base-font-size: 16px;

@function px-to-rem($size) {
  @return math.div($size, $base-font-size) * 1rem;
}

@function fluid-type($min-vw, $max-vw, $min-size, $max-size) {
  @return clamp(px-to-rem($min-size), calc(#{px-to-rem($min-size)} + #{math.div($max-size - $min-size, $max-vw - $min-vw)} * (100vw - #{$min-vw})), px-to-rem($max-size));
}

.projectPage {
  padding-top: px-to-rem(144.2px);
  padding-bottom: px-to-rem(80px);
  display: flex;
  justify-content: center;
  width: 100%;
}

.contentContainer {
  max-width: 1310px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  text-align: left;
  transition: padding 0.5s ease;
  padding-left: px-to-rem(15px);
  padding-right: px-to-rem(15px);
}

h1 {
  font-size: fluid-type(320px, 1920px, 32px, 32px);
  margin: 0 0 px-to-rem(20px);
  text-align: left;
  color: #333333;
  font-family: 'Amiri', sans-serif;
  font-weight: normal;
}

.infoSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px 0;
  border-top: 1px solid rgba(34, 34, 34, 0.1);

  .infoColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-right: 1px solid rgba(34, 34, 34, 0.1);

    .title {
      font-size: px-to-rem(13px);
      font-family: 'Poppins', sans-serif;
      color: #22222280;
    }

    .detail, a {
      font-size: px-to-rem(20px);
      font-family: 'Amiri', sans-serif;
      color: #333333;
      margin-top: 5px;
      text-decoration: none;
    }
  }

  .linkColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
      font-size: px-to-rem(13px);
      font-family: 'Poppins', sans-serif;
      color: #22222280;
    }

    .detail, a {
      font-size: px-to-rem(20px);
      font-family: 'Amiri', sans-serif;
      color: #333333;
      margin-top: 5px;
      text-decoration: none;
    }
  }
}

.image {
  width: 100%;
  height: auto;
  margin-top: px-to-rem(40px);
  margin-bottom: px-to-rem(20px);
}

.constructionFootnote {
  font-size: px-to-rem(20px);
  font-family: 'Amiri', sans-serif;
  color: #333333; // Starting color
  margin-top: px-to-rem(40px);
  text-align: center;
  animation: colorBlink 3s infinite; // Apply the blinking color animation
}

.emoji {
  display: inline-block;
  vertical-align: middle;
  width: 1.2em;
  height: 1.2em;
  margin-left: 0.2em;
}

@keyframes colorBlink {
  0%, 100% {
    color: #333333; // Dark black color
  }
  50% {
    color: #aaaaaa; // Much lighter gray for more noticeable change
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
  animation-delay: 0.3s;
}

@media (max-width: 1024px) {
  .infoSection .infoColumn, .infoSection .linkColumn {
    border-right: none;
  }
}

@media (max-width: 768px) {
  .constructionFootnote {
    font-size: px-to-rem(18px);
  }
}

@media (max-width: 480px) {
  .constructionFootnote {
    font-size: px-to-rem(16px);
  }
}
