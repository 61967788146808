.contact-form-container {
  width: calc(100% - 80px); // Adjust width to account for the new left and right margins
  max-width: 1280px; // Maintain this max width
  margin: 0 auto; // Centers the container
  padding: 160px 40px; // Top and bottom padding remains 60px, left and right padding now 40px
  text-align: left;

  h2 {
    font-size: 32px;
    font-weight: normal;
    font-family: 'Amiri', sans-serif;
    color: #333;
    margin: 0 0 0 0; // 20px bottom margin for the "Get in Touch" heading
  }

  .separator {
    height: 1px;
    background-color: #2222221a;
    margin: 20px 0 40px 0; // 20px margin on top, 40px margin on the bottom
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 40px; // Sets the vertical gap between form groups

    .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      label {
        font-size: 13px;
        font-family: 'Poppins', sans-serif;
        color: #333;
        margin-bottom: 8px;
      }

      input,
      textarea {
        width: 100%;
        border: none;
        border-bottom: 1px solid #2222221a;
        font-family: 'Amiri', sans-serif;
        font-size: 20px;
        color: #333;
        ;
        padding: 10px 0;

        &::placeholder {
          color: #cccccc;
        }

        &:focus {
          outline: none;
        }
      }

      textarea {
        resize: none;
        height: 120px;
      }
    }

    .submit-btn {
      align-self: flex-start; // Aligns the button to the left
      width: 119.14px; // Fixed width
      height: 60px; // Fixed height
      padding: 10px 30px;
      border: 1px solid #cccccc;
      background-color: transparent;
      font-family: 'Poppins', sans-serif;
      color: #333;
      font-size: 13px;
      text-transform: uppercase;
      cursor: pointer;
      transition: background-color 200ms ease, color 200ms ease;

      &:hover {
        background-color: #333;
        color: #fff;
      }
    }
  }

  // Media queries for responsiveness, matching the header's behavior
  @media (max-width: 1360px) {
    // width: auto; // Resets width to adjust automatically
    padding: 60px 40px; // Maintain 40px padding on the sides
  }

  @media (max-width: 1024px) {
    padding: 60px 20px; // Reduce padding on smaller screens

    h2,
    .separator,
    .contact-form {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  @media (max-width: 768px) {
    padding: 60px 10px; // Further reduce padding for smaller screens

    h2,
    .separator,
    .contact-form {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  @media (max-width: 480px) {
    padding: 60px 10px; // Minimal padding for mobile screens

    h2,
    .separator,
    .contact-form {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
