// FleetStatusDashboard.module.scss

$base-font-size: 16px;

@use "sass:math";

@function px-to-rem($size) {
  @return math.div($size, $base-font-size) * 1rem;
}

@function fluid-type($min-vw, $max-vw, $min-size, $max-size) {
  @return clamp(px-to-rem($min-size), calc(#{px-to-rem($min-size)} + #{math.div($max-size - $min-size, $max-vw - $min-vw)} * (100vw - #{$min-vw})), px-to-rem($max-size));
}

.projectPage {
  
  padding-top: px-to-rem(144.2px);
  padding-bottom: px-to-rem(80px);
  display: flex;
  justify-content: center;
  width: 100%;

  .contentContainer {

    max-width: 1310px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    text-align: left;
    transition: padding 0.5s ease;
    padding-left: px-to-rem(15px);
    padding-right: px-to-rem(15px);
    h1 {
      font-size: fluid-type(320px, 1920px, 32px, 32px);
      margin: 0 0 px-to-rem(20px);
      text-align: left;
      color: #333333;
      font-family: 'Amiri', sans-serif;
      font-weight: normal;
    }
    .infoSection {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 20px;
      padding: 20px 0;
      border-top: 1px solid rgba(34, 34, 34, 0.1);
      .infoColumn {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-right: 1px solid rgba(34, 34, 34, 0.1);
        .title {
          font-size: px-to-rem(13px);
          font-family: 'Poppins', sans-serif;
          color: #22222280;
        }
        .detail, a {
          font-size: px-to-rem(20px);
          font-family: 'Amiri', sans-serif;
          color: #333333;
          margin-top: 5px;
          text-decoration: none;
        }
      }
      .linkColumn {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title {
          font-size: px-to-rem(13px);
          font-family: 'Poppins', sans-serif;
          color: #22222280;
        }
        .detail, a {
          font-size: px-to-rem(20px);
          font-family: 'Amiri', sans-serif;
          color: #333333;
          margin-top: 5px;
          text-decoration: none;
        }
      }
    }
    .image {
      width: 100%;
      height: auto;
      margin-top: px-to-rem(40px);
      margin-bottom: px-to-rem(20px);
    }
    .projectSummaryText {
      font-size: px-to-rem(32px);
      font-family: 'Amiri', sans-serif;
      color: #333333;
      margin: 80px auto 40px;
      padding: 0 40px;
      max-width: 770px;
      text-align: center;
      margin-bottom: 40px;
    }
    figure {
      text-align: center;
      margin-top: px-to-rem(40px);
      .image {
        width: 100%;
        border: 0.5px solid black;
      }
      figcaption {
        font-size: px-to-rem(16px);
        font-style: italic;
        font-family: 'Amiri', sans-serif;
        text-align: center;
        margin-top: px-to-rem(5px);
      }
    }
    .paragraphSection {
      text-align: left;
      max-width: 600px;
      margin: 80px auto 20px;
      padding: 0 1rem;
      h2 {
        font-size: 30px;
        font-family: 'Amiri', sans-serif;
        color: #333333;
        margin: 0px 0px 10px;
        line-height: 40px;
        margin-top: 80px;
        text-align: left;
      }
      p {
        font-size: 20px;
        font-family: 'Amiri', sans-serif;
        color: #333333;
        margin: 20px 0;
        line-height: 30px;
        text-align: left;
      }
    }
    .testimonialSection {
      text-align: center;
      padding: 4vh 0;
      max-width: 1280px;
      margin: 80px auto 20px;
      .testimonialQuote {
        font-family: 'Amiri', sans-serif;
        font-style: italic;
        color: #333333;
        margin: 0 auto;
        max-width: 600px;
        padding: 0 1rem;
        @media (min-width: 320px) {
          font-size: calc(22px + (30 - 22) * ((100vw - 320px) / (1280 - 320)));
        }
        @media (min-width: 1280px) {
          font-size: 30px;
        }
      }
      .testimonialAuthor {
        font-family: 'Poppins', sans-serif;
        color: #22222280;
        font-size: 13px;
        margin-top: 0.5rem;
        display: block;
      }
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    .fadeIn {
      opacity: 0;
      animation: fadeIn 0.5s ease-in forwards;
      animation-delay: 0.3s;
    }
  }
}

@media (max-width: 1024px) {
  .testimonialSection, .paragraphSection {
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  .infoSection .infoColumn, .infoSection .linkColumn {
    border-right: none;
  }
}

@media (max-width: 768px) {
  .testimonialSection, .paragraphSection {
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
}

@media (max-width: 480px) {
  .testimonialSection {
    margin-top: 1vh;
    margin-bottom: 1vh;
    padding: 0;
  }
  .paragraphSection {
    margin: 20px 0;
    h2, p {
      font-size: px-to-rem(16px);
    }
  }
}